import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"

const TallOuterContainer = styled.div.attrs(({ dynamicHeight }) => ({
  style: { height: `${dynamicHeight}px` },
}))`
  position: relative;
  width: 100%;
`

const StickyInnerContainer = styled.div`
  position: sticky;
  top: 0;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  @media (min-width: 568px) and (orientation: landscape) {
    height: 150vh;
  }
  @media (min-width: 600px) and (orientation: landscape) {
    height: 120vh;
  }
  @media (min-width: 640px) and (orientation: landscape) {
    height: 140vh;
  }
  @media (min-width: 812px) and (orientation: landscape) {
    height: 150vh;
  }
  @media (min-width: 823px) and (orientation: landscape) {
    height: 140vh;
  }
  @media (min-width: 992px) and (orientation: landscape) {
    height: 100vh;
  }
`

const HorizontalTranslateContainer = styled.div.attrs(({ translateX }) => ({
  style: { transform: `translateX(${translateX}px)` },
}))`
  position: absolute;
  height: 100%;
  will-change: transform;
  display: flex;
  align-items: center;
  overflow: hidden;
`

const calcDynamicHeight = objectWidth => {
  const vw = window.innerWidth
  const vh = window.innerHeight
  return objectWidth - vw + vh + 150
}

const handleDynamicHeight = (ref, setDynamicHeight) => {
  const objectWidth = ref.current.scrollWidth
  const dynamicHeight = calcDynamicHeight(objectWidth)
  setDynamicHeight(dynamicHeight)
}

const applyScrollListener = (ref, setTranslateX) => {
  window.addEventListener("scroll", () => {
    const offsetTop = -ref.current.offsetTop
    setTranslateX(offsetTop)
  })
}

export default ({ children }) => {
  const [dynamicHeight, setDynamicHeight] = useState(null)
  const [translateX, setTranslateX] = useState(0)

  const containerRef = useRef(null)
  const objectRef = useRef(null)

  const resizeHandler = () => {
    handleDynamicHeight(objectRef, setDynamicHeight)
  }

  useEffect(() => {
    handleDynamicHeight(objectRef, setDynamicHeight)
    window.addEventListener("resize", resizeHandler)
    applyScrollListener(containerRef, setTranslateX)
  }, [])

  return (
    <TallOuterContainer dynamicHeight={dynamicHeight}>
      <StickyInnerContainer ref={containerRef}>
        <HorizontalTranslateContainer translateX={translateX} ref={objectRef}>
          {children}
        </HorizontalTranslateContainer>
      </StickyInnerContainer>
    </TallOuterContainer>
  )
}
