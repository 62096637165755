import React from "react"

import HorizontalScroll from "@components/horizontalscroll"

const caseStudyScroll = ({ data }) => {
  console.log(data)
  return (
    <HorizontalScroll>
      <div className="scroll-container">
        <div className="hidden-component pb-0">
          <div className="d-flex justify-content-center align-items-start flex-row flex-nowrap">
            {data.map((study, index) => (
              <div className="case-study-item" key={study.node.id}>
                <div className="row align-items-start justify-content-center">
                  <div className="col-lg-5 mb-4 mb-md-0">
                    <div className="pb-4 pt-0">
                      <h2 className="display-5 font-weight-bold text-light-blue m-0">
                        {study.node.title}
                      </h2>
                      <h4 className="text-white-50 font-weight-normal">
                        from {study.node.logo.title}
                      </h4>
                    </div>
                    <img
                      src={study.node.graphImage.asset.fluid.src}
                      alt="chart"
                      className="w-100"
                    />
                  </div>
                  <div className="col-lg-4 text-center text-white">
                    <img
                      src={study.node.logo.logo.asset.fixed.src}
                      className="img-fluid casestudy-logo d-block mx-auto mb-4"
                      alt={study.node.logo.title}
                      width={150}
                    />
                    <img
                      alt=""
                      className="round-image-shadow shadow-dark mb-3 img-fluid"
                      src={study.node.headshots[0].image.asset.fixed.src}
                      width={150}
                      height={150}
                    />

                    <div className="pt-2" style={{ lineHeight: "1.5" }}>
                      <h4 className="my-1">{study.node.state1}</h4>
                      and
                      <h4 className="my-1">{study.node.state2}</h4>
                      in
                      <h4 className="my-1">{study.node.timeFrame}</h4>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </HorizontalScroll>
  )
}

export default caseStudyScroll
